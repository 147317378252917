import { Image, Container } from "react-bootstrap";
import AdImage from "../../images/purpleAd.png";

function Article() {
  return (
    <Container>
      <div className="jumbotron text-mid">
        <div className="smalltron">
          <h2>長效散瞳藥水</h2>
        </div>
        <div className="smalltron">
          <p>
            散瞳劑，又稱為睫狀肌鬆弛劑，是目前普遍控制孩子近視度數的最大推手之一，當眼睛在看近物時，睫狀肌會用力收縮，水晶體變厚，以致暫時性無法放鬆，屈光度數就會增加，台灣學童近視，長久以來都是教育部衛生福利部以及國民健康局所特別重視的課題，隨著孩童近視人口比例直線上升，也是全國眼科醫師長期對抗的一大棘手難題之一。
          </p>
        </div>
        <div className="smalltron">
          <h3>散瞳藥水的原理：</h3>
          <p>
            長期看近物（例：看書、寫作業）或是用眼不良時，會讓睫狀肌處於過度緊繃的狀態，散瞳藥水的原理是利用藥物強制放鬆睫狀肌，抑制眼睛調節力的作用，同時因為近距離視力模糊的關係，可以減少孩子使用手機的頻率，也可以透過此機會教導兒童正確的閱讀距離。
          </p>
        </div>
        <div className="smalltron">
          <h3>假性近視是什麼？</h3>
          <p>
            睫狀肌因為近距離視物過久而過度用力，導致無法放鬆所表現出來的近視度數，短期可能造成眼睛疲勞，長期則可能讓水晶體一直處於變厚的狀態，使光線過度曲折，形成假性近視，通常發生於視力尚未發育完全的幼童或兒童。
          </p>
        </div>
        <div className="smalltron">
          <h3>什麼是長效藥水？跟短效藥水差別在於哪裡？</h3>
          <p>
            長效散瞳劑，顧名思義【藥效時間很長】，可達連續一週維持瞳孔散大的效果，長效散瞳劑的成分是阿托品（Atropine），
            市售的濃度有0.01%、0.025%、0.05%、0.125%、0.25%、0.3%、0.5%、1%，藥水濃度越高，其效果越好，但怕光的副作用也越大。點長效散瞳劑後，可以抑制眼睛調節力的成效遠過於短效散瞳劑，且不會造成刺痛感的發生，小缺點是隔天起床會感到畏光不適，因為散瞳藥水濃度較高的因素，畏光的副作用就更加嚴重，這也是孩子們最抗拒點長效散瞳劑的原因，而短效散瞳劑的藥效只有4至6小時，隨著睡眠時間過去，到了早上起床自然不會產生白天畏光的情況，但是對於近視控制來說，卻起不了太大的幫助，短效散瞳劑只能用來讓過度用力的眼睛暫時性放鬆，常用於：兒童護眼檢查、白內障術前檢查、糖尿病篩檢…等等，減少所謂的「假性近視度數」，以長期來說，若要減緩近視度數的增加，長效散瞳藥水才較有顯著的效果。
          </p>
        </div>
        <div className="smalltron">
          <h3>點散瞳劑後，近視度數有可能下降嗎？</h3>
          <p>
            散瞳劑，並無法讓真實的近視度數減少，僅能延緩近視度數增加以及眼軸增長的速度，主要是期望在孩童眼球發育階段時，不要演變成為高度近視，引起各種眼睛併發症，但若是針對睫狀肌過度收縮所導致的假性近視，使用散瞳劑確實可以放鬆睫狀肌，進而改善度數。
          </p>
        </div>
        <div className="smalltron">
          <h3>一瓶藥水可以點多久？若沒依照醫生指示回診，度數上會產生什麼樣的變化呢？</h3>
          <p>
            無論是短效或是長效藥水只要任意中斷使用，都可能導致近視度數的回彈，這也是醫生為什麼常常叮嚀孩子一個月至少回診追蹤一次視力狀態的原因，藥水開瓶後，使用期效為一個月。
          </p>
        </div>
        <div className="smalltron">
          <h3>點了散瞳藥水後，孩子變得很怕光，也很抗拒點散瞳藥水，該怎麼辦呢？</h3>
          <p>
            可在外出時配戴遮陽工具，例如：太陽眼鏡、遮陽帽、變色鏡片以減少光線進入造成眼睛的刺激，特別要注意的地方是【請勿擅自停藥】，否則可能導致度數回彈更加嚴重，若還是出現強烈畏光的情形，可向眼科醫師討論是否可調降散瞳濃度或是以其他近視控制產品取代散瞳藥水，例如：角膜塑型片。
          </p>
          <p>
            無論選擇何種近視控制方式，都須遵守醫師指示，定期追蹤監控度數以及視力上的變化，選用散瞳劑來進行近視控制時，務必經過專業眼科醫師的評估，另外，在日常生活中也應培養好的戶外活動習慣，並把握近視黃金治療期，才能確實達到近視控制的效果。
          </p>
        </div>
      </div>
    </Container>
  );
}

export default Article;
